import React, { useState } from 'react';

import { ReactComponent as FemaleIcon } from '../../images/icons/female.svg';
import { ReactComponent as MaleIcon } from '../../images/icons/male.svg';
import { ReactComponent as PlaceholderIcon } from '../../images/icons/userPlaceholder.svg';
import styles from './Avatar.module.scss';

const Avatar = ({ src, salutation }) => {
  const [error, setError] = useState(null);

  if (error) {
    if (salutation === 'mr') {
      return <MaleIcon className={styles.avatarDefault} />;
    }

    if (salutation === 'mrs') {
      return <FemaleIcon className={styles.avatarDefault} />;
    }

    return <PlaceholderIcon className={styles.avatarDefault} />;
  }
  if (!src) {
    return <PlaceholderIcon className={styles.avatarDefault} />;
  }
  return <img src={src} alt="avatar" onError={setError} />;
};

Avatar.displayName = 'Avatar';
export default Avatar;
